import { Button, Modal, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import ModalHeaderComponent from '../../../../components/ModalHeader';

type Props = {
  onClose: () => void;
  updateLessonStatus: (e: any) => void;
};

export default function AgreementLessonModal({ onClose, updateLessonStatus }: Props) {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeaderComponent title="レッスンを承認しますか？" />
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() =>
                updateLessonStatus({
                  status: 'approved',
                })
              }
              leftIcon={<CheckIcon />}
            >
              承認
            </Button>
            <Button colorScheme="red" onClick={closeModal}>
              キャンセル
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
