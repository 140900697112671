const removeCookie = (key: string) => {
  document.cookie = `${key}=; max-age=0`;
};
const cookieAnalize = (allCookie: string) => {
  return {
    searchCookies: (startString: string) => {
      return allCookie.split('; ').filter((row) => row.startsWith(startString));
    },
  };
};
export const cookieUtils = { removeCookie, cookieAnalize };
