import {
  Accordion,
  AccordionItem,
  AccordionButton,
  HStack,
  Box,
  Progress,
  AccordionIcon,
  AccordionPanel,
  Flex,
} from '@chakra-ui/react';
import React from 'react';
import { FieldNames } from '../../..';

type Props = {
  drillRecords: any[];
};
const DrillRecords = ({ drillRecords }: Props) => {
  return (
    <>
      <Box fontSize={'md'} p={'0.5'} alignContent="left" key={Math.random()}>
        ■{/* ドリル */}
        {FieldNames.Self_040}
      </Box>
      {drillRecords.map((drillRecord, i) => (
        <Accordion m="5px" allowMultiple key={i}>
          <AccordionItem bgColor="white" border="1px solid" my="5px">
            <h2>
              <AccordionButton p="5px" justifyContent={'space-between'} border="1px solid">
                <HStack
                  width={'100%'}
                  align="left"
                  justifyContent={'space-between'}
                  alignItems="center"
                >
                  <Box w={'100px'}>{drillRecord?.drill_name}</Box>
                  <Box alignContent={'center'}>
                    <Progress
                      hasStripe
                      value={(drillRecord?.correct / drillRecord?.total) * 100}
                      w="180px"
                    />
                  </Box>
                  <Box textAlign="right" paddingLeft={'0.5'} w="60px">
                    {drillRecord?.correct}/{drillRecord?.total}
                  </Box>
                  <AccordionIcon />
                </HStack>
              </AccordionButton>
            </h2>
            <AccordionPanel border="1px" borderTop={'gray'}>
              <Flex paddingY={'0.5'}>
                <Box backgroundColor="white" textAlign="left" fontSize="xs" width={'100px'}>
                  {FieldNames.Self_030}
                </Box>
                <Box backgroundColor="white" textAlign="left" fontSize="xs" width={'100px'}>
                  {drillRecord?.max_drill_attempt ?? '0'}回
                </Box>
              </Flex>
              <Flex alignItems={'center'} justifyContent="space-between">
                <Box textAlign="left" fontSize="xs" paddingRight={'1'} w="90px">
                  {FieldNames.Self_020}
                </Box>
                <Box>
                  <Progress
                    hasStripe
                    value={(drillRecord?.avg_last3 / drillRecord?.total) * 100}
                    w="200px"
                  />
                </Box>
                <Box w="42px" textAlign="right" fontSize="xs">
                  {drillRecord?.avg_last3}/{drillRecord?.total}
                </Box>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}
    </>
  );
};
export default DrillRecords;
