import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Flex,
  Box,
  AccordionIcon,
  AccordionPanel,
  Stack,
  Progress,
  Image,
} from '@chakra-ui/react';
import imgBronze from '../../../assets/images/ico_copper@2x.png';
import imgGold from '../../../assets/images/ico_gold@2x.png';
import imgSilver from '../../../assets/images/ico_silver@2x.png';
type Props = {
  correctCount: number;
  totalCount: number;
};
const MedalIcon = ({ correctCount, totalCount }: Props) => {
  const [imageSrc, setImageSrc] = useState('');
  const setMeadlImage = () => {
    if (typeof correctCount !== 'number' || typeof totalCount !== 'number') {
      return;
    }
    if (totalCount === 0) {
      return;
    }

    let src = '';

    if (correctCount === totalCount) {
      src = imgGold;
    } else if (correctCount / totalCount > 0.7) {
      src = imgSilver;
    } else if (correctCount > 0) {
      src = imgBronze;
    }
    setImageSrc(src);
  };
  useEffect(() => {
    setMeadlImage();
  }, [correctCount, totalCount]);
  return <>{imageSrc ? <Image src={imageSrc} w="20px" alt={'MedalIcon'} /> : <></>}</>;
};
export default MedalIcon;
