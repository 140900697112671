import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  Progress,
  VStack,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { FieldNames } from '../../..';
import { ExpressionData } from '../../../../../_types/story';
import { getFormattedDate, getFormattedDatetime } from '../../../../../components/Format';
import { KURASHI_STUDY_API_PATH, TOTAL_COUNT } from '../../../../../configs';
type Props = {
  section: any;
  index: number;
  storyRecords: any[];
};
const Scene = ({ section, index, storyRecords }: Props) => {
  let ids = section.Id.split('_');
  let id = ids[3];
  let chapterId = section?.chapter + `_${index + 1}`;
  const [storyRecord, setStoryRecord] = useState<any>();
  const [expressions, setExpressions] = useState<any[]>([]);
  const [opend, setOpend] = useState(false);
  const [expressionFetchComplated, setExpressionFetchComplated] = useState(false);

  const handleAccordion = () => {
    setOpend(!opend);
  };
  useEffect(() => {
    if (opend) {
      getExpressions(chapterId);
    }
  }, [opend]);
  const getExpressions = useCallback(
    (chapterId: string) => {
      fetch(
        KURASHI_STUDY_API_PATH + 'expression/list?keyword=&sub_key=' + chapterId + '_SECTION_ID',
        { method: 'GET' },
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.result && Array.isArray(data?.result)) {
            const exps = (data?.result as any[])
              .filter((e) => e.data && Array.isArray(e.data))
              .map((e) => {
                let title = '';
                const titles = (e.data as ExpressionData[]).filter((e) => e?.key === 'FormTitle');
                if (titles.length > 0) {
                  title = titles[0].data
                    .map((dd) => dd.value.ja)
                    .flat()
                    .join('');
                }
                return title;
              });
            setExpressions(exps);
            setExpressionFetchComplated(true);
          }
        });
    },
    [KURASHI_STUDY_API_PATH],
  );

  useEffect(() => {
    storyRecords
      .filter((record) => record?.chapter_id === section?.chapter + `_${index}`)
      .forEach((record) => {
        setStoryRecord(record);
      });
  }, [storyRecords, section]);

  return (
    <>
      <Accordion defaultIndex={[-1]} allowMultiple key={section.Id + index}>
        <AccordionItem bgColor="white" border="1px solid" my="5px">
          <h2>
            <AccordionButton
              p="5px"
              justifyContent={'space-between'}
              border="1px solid"
              onClick={handleAccordion}
            >
              <VStack width={'100%'} align="left" justifyContent={'space-between'}>
                <Flex justify="space-between" w={'100%'}>
                  <Box textAlign="left" fontSize="xs" title={section?.title}>
                    {id}-{index + 1}:{' '}
                    {section?.title.length <= 9
                      ? section?.title
                      : section?.title.slice(0, 9) + '...'}
                  </Box>
                  <Box fontSize="xs" paddingLeft={'0.5'} w="max-content">
                    {FieldNames.Story_050}:
                    {storyRecord?.last_end ? getFormattedDate(storyRecord?.last_end) : '---'}
                  </Box>
                  <AccordionIcon />
                </Flex>
                <Flex alignItems={'center'}>
                  <Box textAlign="left" fontSize="xs" width={'60px'}>
                    練習問題:
                  </Box>
                  <Box>
                    <Progress
                      hasStripe
                      value={
                        ((storyRecord?.correct ? storyRecord?.correct : 0) / TOTAL_COUNT.SCENE) *
                        100
                      }
                      w="220px"
                    />
                  </Box>
                  <Box w="42px" textAlign="right" fontSize="xs">
                    {storyRecord?.correct ? storyRecord?.correct : 0} / {TOTAL_COUNT.SCENE}
                  </Box>
                </Flex>
              </VStack>
            </AccordionButton>
          </h2>
          <AccordionPanel border="1px" borderTop={'gray'}>
            <Flex justifyContent="space-between" paddingY={'0.5'}>
              <Box backgroundColor="white" textAlign="left" fontSize="xs" width={'60px'}>
                {FieldNames.Story_020}
              </Box>
              <Box backgroundColor="white" textAlign="left" fontSize="xs" width={'40px'}>
                {storyRecord?.max_quiz_attempt ?? 0}回
              </Box>
              <Box backgroundColor="white" textAlign="center" fontSize="xs">
                {storyRecord ? (
                  storyRecord?.study_status === 'Complete' ? (
                    <Badge colorScheme="cyan" width="6rem" p={'5px'}>
                      {/* 学習済み */}
                      {FieldNames.Common_001}
                    </Badge>
                  ) : (
                    <Badge colorScheme="yellow" width="6rem" p={'5px'}>
                      {/* 学習中 */}
                      {FieldNames.Common_002}
                    </Badge>
                  )
                ) : (
                  <Badge colorScheme="green" width="6rem" p={'5px'}>
                    {/* 未学習 */}
                    {FieldNames.Common_003}
                  </Badge>
                )}
              </Box>
            </Flex>
            <Flex paddingY={'0.5'}>
              <Box fontSize={'xs'} width="100px">
                {FieldNames.Story_030}
              </Box>
              <Box fontSize={'xs'}>
                {storyRecord?.first_start
                  ? storyRecord?.first_start
                    ? getFormattedDatetime(storyRecord?.first_start)
                    : '---'
                  : '---'}
              </Box>
            </Flex>
            <Flex paddingY={'0.5'}>
              <Box fontSize={'xs'} width="100px">
                {FieldNames.Story_040}
              </Box>
              <Box fontSize={'xs'}>
                {storyRecord?.first_end ? getFormattedDatetime(storyRecord?.first_end) : '---'}
              </Box>
            </Flex>
            <Flex justifyContent="space-between" paddingY={'0.5'}>
              <Box fontSize={'xs'} width="100px">
                目標:
              </Box>
              <Box fontSize="xs" width={'300px'} alignContent="flex-start">
                {section?.goal['ja']}
              </Box>
            </Flex>
            <Flex>
              <Box fontSize={'xs'} width="100px">
                詳細:
              </Box>
              <Box fontSize="xs">
                {expressions.map((exp, i) => (
                  <div key={section.Id + ':exp_' + i}>
                    <Text>{exp}</Text>
                  </div>
                ))}
                {!expressionFetchComplated && <Spinner color="teal" />}
              </Box>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
export default Scene;
