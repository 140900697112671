// HACK 共通化のため一旦画面側から剥がしたが、Lesson情報を保持するモデルクラスに実装した方がいいと思う
import {
  APPROVAL_FLG,
  CANCEL_FLG,
  DELETE_FLG,
  LESSON_ROOM_ENTRY_BEFORE_TIME_MIN,
} from '../../configs';
import { dateUtils } from '../../utils/dateUtils';

/**
 * 入室可能か判定する
 * @param startDatetime
 * @param endDatetime
 * @param approvalFlg
 * @param cancelFlg
 * @returns
 */
export const getIsRoomEntryEnabled = (
  startDatetime: string,
  endDatetime: string,
  approvalFlg: boolean | number,
  cancelFlg: boolean | number,
) => {
  if (typeof approvalFlg === 'number' && approvalFlg !== APPROVAL_FLG.APPROVED) {
    return false;
  }
  if (typeof approvalFlg === 'boolean' && !approvalFlg) {
    return false;
  }
  if (typeof cancelFlg === 'number' && cancelFlg !== CANCEL_FLG.NONE) {
    return false;
  }
  if (typeof cancelFlg === 'boolean' && !cancelFlg) {
    return false;
  }

  const currentTime = new Date();
  const lessonStartTime = new Date(startDatetime);
  const lessonEndTime = new Date(endDatetime);
  if (dateUtils.isInvalidDate(lessonStartTime) || dateUtils.isInvalidDate(lessonEndTime)) {
    return false;
  }

  //get time difference
  const startDiff = (lessonStartTime.getTime() - currentTime.getTime()) / 1000;
  const endDiff = (lessonEndTime.getTime() - currentTime.getTime()) / 1000;

  // NOTE diffを分に変換して計算する場合は、roundを使わない。ceil
  if (startDiff <= LESSON_ROOM_ENTRY_BEFORE_TIME_MIN * 60 && endDiff >= 0) {
    return true;
  } else {
    return false;
  }
};
export const getIsNotAttendees = (lesson: any) => {
  if (Array.isArray(lesson?.t_lesson_learner)) {
    // 参加者が存在している
    const isExists = lesson?.t_lesson_learner.some((e: any): boolean => {
      if (typeof e.delete_flg === 'boolean' && !e.delete_flg) {
        return true;
      }
      if (typeof e.delete_flg === 'number' && e.delete_flg === DELETE_FLG.AVAILABLE) {
        return true;
      }
      return false;
    });
    return !isExists;
  }
  return true;
};
