import { AlertStatus, useToast } from '@chakra-ui/react';

const useAppToast = () => {
  const toast = useToast();
  const displayToast = (
    title: string,
    description: string,
    toastStatus?: AlertStatus,
    options?: {
      duration?: number;
    },
  ) => {
    setTimeout(() => {
      toast({
        title: title,
        description: description,
        status: toastStatus,
        duration: options?.duration || 5000,
        isClosable: true,
        position: 'top',
      });
    }, 1000);
  };
  return { displayToast };
};
export default useAppToast;