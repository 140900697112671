import {
  Button,
  Modal,
  ModalBody,
  Flex,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import ModalHeaderComponent from '../../../../components/ModalHeader';

export default function ThemeModal({ onCloseModal, onOpenModal, lesson }: any) {
  return (
    <>
      <Modal isOpen={true} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent minW="55rem">
          <ModalHeaderComponent title="テーマ詳細" />
          <ModalBody>
            <label>テーマ</label>
            <Flex justifyContent="right"></Flex>
            <Textarea placeholder="テーマが入力されていません" rows={3} readOnly>
              {lesson?.theme}
            </Textarea>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => onOpenModal('themeEditModal', lesson)}>
              {"編集"}
            </Button>
            <Button colorScheme="gray" mr={3} onClick={onCloseModal}>
              {"閉じる"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
