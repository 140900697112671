import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react';
import ModalHeaderComponent from '../../../../components/ModalHeader';
import { getFormattedDate, getFormattedTime } from '../../../../components/Format';

type Props = {
  onClose: () => void;
  onOpenModal: (name: string, item: any) => void;
  pastLesson: any;
};

export default function FeedbackModal({ onClose, onOpenModal, pastLesson }: Props) {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent minW="55rem">
          <ModalHeaderComponent title={'フィードバック詳細'} />
          <ModalBody>
            <Flex justifyContent="right"></Flex>
            <Flex>レッスン日時</Flex>
            <Flex border="1px solid #ddd" p={2} maxW="100%" overflowY="auto">
              <Box w="50%">Lesson Date: {getFormattedDate(pastLesson?.start_datetime)}</Box>
              <Spacer />
              <Box>
                Lesson Duration: {getFormattedTime(pastLesson?.start_datetime)}
                {' ~ '}
                {getFormattedTime(pastLesson?.end_datetime)}
              </Box>
            </Flex>
            <Flex>フィードバック内容</Flex>
            <p
              className={
                pastLesson?.t_lesson_learner && pastLesson.t_lesson_learner[0]?.feed_back !== ''
                  ? 'Feedback-details'
                  : 'Feedback-details-readOnly'
              }
            >
              {pastLesson?.t_lesson_learner && pastLesson.t_lesson_learner[0]?.feed_back !== ''
                ? pastLesson.t_lesson_learner[0]?.feed_back
                : 'フィードバックが入力されていません'}
            </p>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="right">
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  onOpenModal('feedbackEditModal', null);
                }}
              >
                編集
              </Button>
              <Button colorScheme="gray" mr={3} onClick={closeModal}>
                閉じる
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
