import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import ModalHeaderComponent from '../../../../components/ModalHeader';

type Props = {
  onClose: () => void;
  onOpenModal: (name: string, item: any) => void;
  lesson: any;
};

export default function FeedbackAlreadyExistModal({ onClose, onOpenModal, lesson }: Props) {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onOverlayClick={() => closeModal} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent minW="40rem">
          <ModalHeaderComponent title={'確認'} />
          <ModalBody>
            <Text fontSize="xl" style={{ justifyContent: 'center', alignItems: 'center' }}>
              既にフィードバックが登録されている学習者が存在します。更新を実施した場合登録済みの内容は上書きされます。続行しますか？
            </Text>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="right">
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => onOpenModal('bulkFeedbackUpdateModal', lesson)}
              >
                編集を続ける
              </Button>
              <Button colorScheme="gray" mr={3} onClick={closeModal}>
                キャンセル
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
