import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Flex,
  Box,
  AccordionIcon,
  AccordionPanel,
  Stack,
  Progress,
  Image,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { TOTAL_COUNT } from '../../../../../configs';
import MedalIcon from '../../../../../components/icon/Medal/Medal';

type Props = {
  storyRecords: any[];
  name: string;
  id: string;
};
const SeasonSummary = ({ storyRecords, name, id }: Props) => {
  const [storyRecord, setStoryRecord] = useState<any>();

  useEffect(() => {
    storyRecords
      .filter((record) => record?.chapter_id === `${id}_0`)
      .forEach((record) => {
        setStoryRecord(record);
      });
  }, [storyRecords]);

  return (
    <>
      <Accordion
        m="5px"
        backgroundColor="white"
        allowMultiple
        border="solid 1px grey"
        borderRadius="5px"
      >
        <AccordionItem>
          <h2>
            <AccordionButton justifyContent="space-between">
              <Flex justifyContent="space-between" w="100%">
                <Box>
                  <Flex alignItems="center">
                    <Box w="max-content">{name.replace(' ', 'まとめ')}</Box>
                  </Flex>
                </Box>
                <Box>
                  <MedalIcon correctCount={storyRecord?.correct} totalCount={storyRecord?.total} />
                </Box>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} backgroundColor="white">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box w="50px">まとめ</Box>
              <Box>
                <Progress
                  hasStripe
                  value={
                    storyRecord?.correct
                      ? (storyRecord?.correct / TOTAL_COUNT.SEASON_SUMMARY) * 100
                      : 0
                  }
                  w="220px"
                />
              </Box>
              <Box w="45px" textAlign="right">
                {storyRecord?.correct ? storyRecord?.correct : '0'}/{TOTAL_COUNT.SEASON_SUMMARY}
              </Box>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
export default SeasonSummary;
