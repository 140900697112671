import {
  Accordion,
  AccordionItem,
  AccordionButton,
  VStack,
  Flex,
  Box,
  AccordionIcon,
  Progress,
  AccordionPanel,
  Badge,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FieldNames } from '../../..';
import { getFormattedDate, getFormattedDatetime } from '../../../../../components/Format';
import { STORY_EPISODE_ID_SECTION_INDEX, TOTAL_COUNT } from '../../../../../configs';

type Props = {
  episodeId: string;
  storyRecords: any[];
  name: string;
};
const EpisodeSummary = ({ episodeId, storyRecords, name }: Props) => {
  const [storyRecord, setStoryRecord] = useState<any>();
  useEffect(() => {
    storyRecords
      .filter(
        (record) =>
          record?.chapter_id === `${episodeId}_${STORY_EPISODE_ID_SECTION_INDEX['Ep.x summary']}`,
      )
      .forEach((record) => {
        setStoryRecord(record);
      });
  }, [storyRecords]);
  return (
    <>
      <Accordion defaultIndex={[-1]} allowMultiple>
        <AccordionItem bgColor="white" border="1px solid" my="5px">
          <AccordionButton p="5px" justifyContent={'space-between'} border="1px solid">
            <VStack width={'100%'} align="lefty" justify="space-between">
              <Flex justify="space-between" width={'100%'}>
                <Box alignItems={'initial'} fontSize="xs">
                  {name.replace(' ', '')}まとめ
                </Box>
                <Box fontSize="xs" paddingLeft={'0.5'} w="max-content">
                  {FieldNames.Story_050}:
                  {storyRecord?.last_end ? getFormattedDate(storyRecord?.last_end) : '---'}
                </Box>
                <AccordionIcon />
              </Flex>
              <Flex alignItems={'center'}>
                <Box textAlign="left" fontSize="xs" width={'60px'}>
                  練習問題:
                </Box>
                <Box>
                  <Progress
                    hasStripe
                    value={
                      storyRecord?.correct
                        ? (storyRecord?.correct / TOTAL_COUNT.EPISODE_SUMMARY) * 100
                        : 0
                    }
                    w="220px"
                  />
                </Box>
                <Box w="42px" textAlign="right" fontSize="xs">
                  {storyRecord?.correct ? storyRecord?.correct.toString() : '0'}/
                  {TOTAL_COUNT.EPISODE_SUMMARY}
                </Box>
              </Flex>
            </VStack>
          </AccordionButton>
          <AccordionPanel border="1px solid">
            <Flex justifyContent="space-between" paddingY={'0.5'}>
              <Box backgroundColor="white" textAlign="left" fontSize="xs" width={'60px'}>
                {FieldNames.Story_020}
              </Box>
              <Box backgroundColor="white" textAlign="left" fontSize="xs" width={'40px'}>
                {storyRecord?.max_quiz_attempt ?? 0}回
              </Box>
              <Box backgroundColor="white" textAlign="center" fontSize="xs">
                {storyRecord ? (
                  storyRecord?.study_status === 'Complete' ? (
                    <Badge colorScheme="cyan" width="6rem" p={'5px'}>
                      学習済み
                    </Badge>
                  ) : (
                    <Badge colorScheme="yellow" width="6rem" p={'5px'}>
                      学習中
                    </Badge>
                  )
                ) : (
                  <Badge colorScheme="green" width="6rem" p={'5px'}>
                    未学習
                  </Badge>
                )}
              </Box>
            </Flex>
            <Flex paddingY={'0.5'}>
              <Box fontSize={'xs'} width="100px">
                {FieldNames.Story_030}
              </Box>
              <Box fontSize={'xs'}>
                {storyRecord?.first_start
                  ? storyRecord?.first_start
                    ? getFormattedDatetime(storyRecord?.first_start)
                    : '---'
                  : '---'}
              </Box>
            </Flex>
            <Flex paddingY={'0.5'}>
              <Box fontSize={'xs'} width="100px">
                {FieldNames.Story_040}
              </Box>
              <Box fontSize={'xs'}>
                {storyRecord?.first_end ? getFormattedDatetime(storyRecord?.first_end) : '---'}
              </Box>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
export default EpisodeSummary;
