import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import ModalHeaderComponent from '../../../../components/ModalHeader';
import { useState } from 'react';

type Props = {
  onClose: () => void;
  handleFeedbackInputChange: (e: any) => void;
  onFeedbackUpdate: () => void;
  pastLesson: any;
};

export default function FeedbackEditModal({
  onClose,
  handleFeedbackInputChange,
  onFeedbackUpdate,
  pastLesson,
}: Props) {
  const [confirming, setConfirming] = useState(false);
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent minW="55rem">
          <ModalHeaderComponent
            title={confirming == false ? 'フィードバック編集' : 'フィードバック編集'}
          />
          <ModalBody>
            <Flex justifyContent="right"></Flex>
            <Textarea
              placeholder="レッスンの内容や学習者のについて記入してください。"
              onChange={handleFeedbackInputChange}
              isDisabled={confirming == true}
              rows={3}
              defaultValue={
                pastLesson?.t_lesson_learner ? pastLesson?.t_lesson_learner[0]?.feed_back : ''
              }
            ></Textarea>
          </ModalBody>
          {confirming == false ? (
            <ModalFooter>
              <Button colorScheme="gray" mr={3} onClick={closeModal}>
                キャンセル
              </Button>
              <Button colorScheme="blue" mr={3} onClick={() => setConfirming(true)}>
                更新
              </Button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={() => onFeedbackUpdate()}>
                更新
              </Button>
              <Button colorScheme="gray" mr={3} onClick={() => setConfirming(false)}>
                修正
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
