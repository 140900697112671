import { useCallback, useContext } from 'react';
import { REACT_APP_ONLINE_LESSON_TEACHER_URL } from '../../configs';
import { currentUserId } from '../../pages/shell';
import { OnlineLessonJoinRequestParams } from '../../_types/onlineLesson/onlineLesson';
import useTeacher from '../useTeacher/useTeacher';

export default function useTwilioRoom(lesson: any) {
  const teacherId = useContext(currentUserId);
  const { teacher } = useTeacher();
  const getRoomLink = useCallback(() => {
    try {
      const lessonId = lesson?.lesson_id ? lesson?.lesson_id : '';
      const roomId = lesson?.room_id ? lesson?.room_id : 'dummy-room';
      const companyId = lesson?.company_id ? lesson?.company_id : '';
      const teacherIdentity = teacher?.nick_name ? teacher?.nick_name : '';
      const quertParams: OnlineLessonJoinRequestParams = {
        companyId,
        userId: teacherId,
        lessonId,
        identity: teacherIdentity,
        roomId,
        type: 'teacher',
        lang: 'ja', // 教師は日本語固定
      };
      const urlSearchParams = new URLSearchParams(quertParams).toString();
      return `${REACT_APP_ONLINE_LESSON_TEACHER_URL}/join-room/?${urlSearchParams}`;
    } catch (err) {
      return '';
    }
  }, [teacherId, teacher, lesson]);

  return { getRoomLink };
}
