import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import ModalHeaderComponent from '../../../../components/ModalHeader';
import { getFormattedDate, getFormattedTime } from '../../../../components/Format';

interface Lesson {
  start_datetime: string;
  end_datetime: string;
}

interface SelectedItem {
  feed_back: string;
}

type Props = {
  onClose: () => void;
  onOpenModal: (name: string, item: any) => void;
  selectedItem: SelectedItem;
  lesson: Lesson;
};

export default function FeedbackModal({ onClose, onOpenModal, selectedItem, lesson }: Props) {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent minW="55rem">
          <ModalHeaderComponent title={'フィードバック詳細'} />
          <ModalBody>
            <Flex justifyContent="right"></Flex>
            <Flex>レッスン日時</Flex>
            <Flex border="1px solid #ddd" p={2} maxW="100%" overflowY="auto">
              <Box w="100%">
                {getFormattedDate(lesson?.start_datetime)}
                {getFormattedTime(lesson?.start_datetime)}
                {' ~ '}
                {getFormattedTime(lesson?.end_datetime)}
              </Box>
            </Flex>
            <Flex>フィードバック内容</Flex>
            <Textarea
              placeholder="フィードバックが入力されていません"
              readOnly
              value={selectedItem?.feed_back}
            ></Textarea>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="right">
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => onOpenModal('feedbackEditModal', selectedItem)}
              >
                編集
              </Button>
              <Button colorScheme="gray" mr={3} onClick={closeModal}>
                閉じる
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
