import { Center } from '@chakra-ui/react';
import React from 'react';
import Plan from './Plan';

type Props = {
  lessonRecords: any[];
};
const LessonRecords = ({ lessonRecords }: Props) => {
  return (
    <>
      {lessonRecords?.map((lesson) => (
        <Plan lessonRecord={lesson} />
      ))}
      {lessonRecords.length === 0 && (
        <Center backgroundColor="yellow"> レッスンデータがありません </Center>
      )}
    </>
  );
};
export default LessonRecords;
