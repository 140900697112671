import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import ModalHeaderComponent from '../../../../components/ModalHeader';

type Props = {
  onClose: () => void;
  handleInputChange: (e: any) => void;
  updateBulkFeedback: () => void;
  selectedItem: any;
  confirming: boolean;
};

export default function BulkFeedbackUpdateModal({
  onClose,
  handleInputChange,
  updateBulkFeedback,
  confirming,
  selectedItem,
}: Props) {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onOverlayClick={() => closeModal} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent minW="40rem">
          <ModalHeaderComponent title={'フィードバック一括編集'} />
          <ModalBody>
            <Textarea
              placeholder="レッスンの内容や学習者のについて記入してください。"
              onChange={handleInputChange}
              defaultValue={selectedItem != '' ? selectedItem?.feed_back : ''}
            ></Textarea>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="right">
              <Button
                colorScheme="blue"
                mr={3}
                disabled={confirming}
                onClick={() => updateBulkFeedback()}
              >
                更新
              </Button>
              <Button colorScheme="gray" mr={3} onClick={closeModal}>
                キャンセル
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
