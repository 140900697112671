import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import ModalHeaderComponent from '../../../../components/ModalHeader';

interface Lesson {
  lesson_day: string;
  start_time: string;
  end_time: string;
  note: string;
  m_company: company;
}

interface company {
  company_name: string;
}

type Props = {
  onClose: () => void;
  onOpenModal:  (name: string, item: any) => void;
  pastLesson: Lesson;
};

export default function NoteModal({ onClose, onOpenModal, pastLesson }: Props) {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent minW="55rem">
          <ModalHeaderComponent title={'申し送り詳細'} />
          <ModalBody>
            <Flex justifyContent="right">
              <Box> 先生 → {pastLesson?.m_company?.company_name} 株式会社 </Box>
            </Flex>
            <Flex>レッスン日時</Flex>
            <Flex border="1px solid #ddd" p={2} maxW="100%" overflowY="auto">
              <Box w="100%">
                {pastLesson?.lesson_day}　{pastLesson?.start_time}～{pastLesson?.end_time}
              </Box>
            </Flex>
            <Flex>申し送り内容</Flex>
            <p
              className={pastLesson?.note ? 'LessonNotes-details' : 'LessonNotes-details-readOnly'}
              contentEditable={false}
            >
              {pastLesson?.note || '申し送りが入力されていません'}
            </p>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="right">
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  onOpenModal('noteEditModal', null);
                }}
              >
                編集
              </Button>
              <Button colorScheme="gray" mr={3} onClick={closeModal}>
                閉じる
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
