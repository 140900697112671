import { useContext, useEffect, useState } from 'react';
import { requestServerApi, serverApi } from '../../api/api';
import { useAppSelector } from '../../hooks';
import { currentUserId } from '../../pages/shell';
import useErrorPage from '../useErrorPage/useErrorPage';

export default function useTeacher() {
  const userState = useAppSelector((state) => state.user);
  const username = userState.user?.['cognito:username'] as string;
  const teacherId = useContext(currentUserId);
  const [teacher, setTeacher] = useState<any>();
  const { apiErrorHandler } = useErrorPage();

  useEffect(() => {
    const getLearner = async () => {
      const response = await requestServerApi<any>(() =>
        serverApi.organizationsControllerGetTeacherUserList(username ?? teacherId, 1, '', '', 1, 1),
      ).catch((error) => {
        apiErrorHandler(error, {
          componentName: 'useTeacher',
          functionName: serverApi.organizationsControllerGetTeacherUserList.name,
        });
      });
      try {
        // TODO データ型と値チェック
        if (Array.isArray(response.data.data)) {
          setTeacher(response.data.data[0][0]);
        }
      } catch (error) {
        apiErrorHandler(error, {
          componentName: 'useTeacher',
          functionName: serverApi.organizationsControllerGetTeacherUserList.name,
        });
      }
    };
    getLearner();
  }, [username]);
  return { teacher };
}
