import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  useTimeout,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FieldNames } from '../..';
import { requestServerApi, serverApi } from '../../../../api/api';
import ModalHeaderComponent from '../../../../components/ModalHeader';
import LessonRecords from './Lesson/LessonRecords';
import SelfStudyRecords from './SelfStudy/SelfStudyRecords';
import StoryStudyRecords from './Story/StoryStudyRecords';

const TAB = {
  STORY: 0,
  LESSON: 1,
  SELF_STUDY: 2,
} as const;
type Props = {
  onClose: (name: string, response: any) => void;
};
export default function StudyHistoryModal({ onClose }: Props) {
  const company_id: string = useParams<any>().companyId;
  const learner_id: string = useParams<any>().learnerId;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [storyRecords, setStoryRecords] = useState<any[]>([]);
  const [selfStudyRecords, setSelfStudyRecords] = useState<any[]>([]);
  const [lessonRecords, setLessonRecords] = useState<any[]>([]);
  const [fetchCompleted, setFetchCompleted] = useState(false);

  const handleClick = (selectedIndex: number) => {
    setSelectedTabIndex(selectedIndex);
  };
  const closeModal = () => {
    onClose('studyHistoryModal', '');
  };

  const activeButtonStyle: React.CSSProperties = {
    width: '120px',
    backgroundColor: 'teal',
    color: 'white',
    border: '2px solid Black',
    borderBottom: '0',
    borderRadius: '10px 10px 0 0',
  };

  const inactiveButtonStyle: React.CSSProperties = {
    width: '120px',
    backgroundColor: '',
    color: '',
    border: '2px solid Black',
    borderBottom: '2px solid Black',
    borderRadius: '10px 10px 0 0',
  };

  const getrecords = () => {
    // NOTE 学習記録を１APIで取得しているため、ここで取得するのが一番効率がいい（ただし分けたほうが負荷が少なくなると思う）
    const apiResponse = requestServerApi<any>(() =>
      serverApi.organizationsControllerGetLearnerStudyRecords(company_id, learner_id),
    );
    apiResponse.then((data) => {
      setStoryRecords(data.data.data[0]?.storyRecords);
      setSelfStudyRecords(data.data.data[0]?.drillRecords);
      setLessonRecords(data.data.data[0]?.lessonRecords);
      setFetchCompleted(true);
    });
  };
  useTimeout(() => {
    // useEffectで開いたと同時に処理を初めると若干重くなるため、少しずらす
    getrecords();
  }, 100);

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeaderComponent title="学習履歴" />

          <ModalBody>
            <Flex justify="center" paddingBottom="0.5rem" gap="0.5" paddingRight="1rem">
              <Button
                style={selectedTabIndex === TAB.STORY ? activeButtonStyle : inactiveButtonStyle}
                onClick={() => handleClick(TAB.STORY)}
              >
                <Box>{FieldNames.Story_010}</Box>
              </Button>
              <Button
                style={selectedTabIndex === TAB.LESSON ? activeButtonStyle : inactiveButtonStyle}
                onClick={() => handleClick(TAB.LESSON)}
              >
                <Box>{FieldNames.Talk_010}</Box>
              </Button>
              <Button
                style={
                  selectedTabIndex === TAB.SELF_STUDY ? activeButtonStyle : inactiveButtonStyle
                }
                onClick={() => handleClick(TAB.SELF_STUDY)}
              >
                <Box>{FieldNames.Self_010}</Box>
              </Button>
            </Flex>
            <Flex overflowY="auto" height="500px" direction={'column'}>
              {fetchCompleted ? (
                <>
                  {selectedTabIndex === TAB.STORY && storyRecords && (
                    <StoryStudyRecords storyRecords={storyRecords} />
                  )}
                  {selectedTabIndex === TAB.SELF_STUDY && selfStudyRecords && (
                    <SelfStudyRecords selfStudyRecords={selfStudyRecords} />
                  )}
                  {selectedTabIndex === TAB.LESSON && lessonRecords && (
                    <LessonRecords lessonRecords={lessonRecords} />
                  )}
                </>
              ) : (
                <Box marginX="auto" marginY="10">
                  <Spinner color="teal" />
                </Box>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
