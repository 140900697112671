import {
  Button,
  Modal,
  ModalBody,
  Flex,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import ModalHeaderComponent from '../../../../components/ModalHeader';
import { useState } from 'react';

export default function ThemeEditModal({
  onCloseModal,
  updateTheme,
  handleInputChange,
  lesson,
}: any) {
  const [confirming, setConfirming] = useState(false);

  const onUpdateTheme = () => {
    setConfirming(true);
    updateTheme();
  };

  return (
    <>
      <Modal isOpen={true} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent minW="55rem">
          <ModalHeaderComponent title={'テーマ編集'} />
          <ModalBody>
            <label>テーマ</label>
            <Flex justifyContent="right"></Flex>
            <Textarea
              placeholder="テーマが入力されていません"
              rows={3}
              onChange={handleInputChange}
            >
              {lesson?.theme}
            </Textarea>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} disabled={confirming} onClick={() => onUpdateTheme()}>
              {'更新'}
            </Button>
            <Button colorScheme="gray" mr={3} disabled={confirming} onClick={onCloseModal}>
              {'キャンセル'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
