import { Button, Modal, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import ModalHeaderComponent from '../../../components/ModalHeader';

type Props = {
  onClose: () => void;
  handlelessonFeeHistoryCreation: () => void;
  confirmedLessonFeeOption: (values: any) => void;
  finalFee: any;
};

export default function ConfirmedLessonFeeOptionModal({
  onClose,
  handlelessonFeeHistoryCreation,
  confirmedLessonFeeOption,
  finalFee,
}: Props) {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeaderComponent title="請求書を送信しますか？" />
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                // FIXME 別々に非同期で行っていいのか？
                handlelessonFeeHistoryCreation();
                confirmedLessonFeeOption(finalFee);
              }}
              leftIcon={<CheckIcon />}
            >
              確認
            </Button>
            <Button colorScheme="red" onClick={closeModal}>
              キャンセル
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
