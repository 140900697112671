import { Center, Spinner } from '@chakra-ui/react';

export default function LoadingSpinner() {
  return (
    <>
      <Center>
        <Spinner id="spinner" thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </Center>
    </>
  );
  }