import EpisodeSummary from './EpisodeSummary';
import Scene from './Scene';

type Props = {
  episode: any;
  storyRecords: any;
};
const EpisodeScenes = ({ episode, storyRecords }: Props) => {
  return (
    <>
      {episode?.sections.map((section: any, i: number) => (
        <div key={i}>
          <Scene index={i} section={section} storyRecords={storyRecords} />
        </div>
      ))}
      <EpisodeSummary episodeId={episode?.Id} storyRecords={storyRecords} name={episode?.name} />
    </>
  );
};
export default EpisodeScenes;
