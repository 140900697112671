import { Badge } from '@chakra-ui/react';
import { APPROVAL_FLG, CANCEL_FLG, STOP_FLG } from '../configs';

export function getLessonStatusBadge(lesson: any){
    let status = "";
    let colorScheme = "";
    if ( lesson?.approval_flg == APPROVAL_FLG.APPROVED ){
      status = "予約";
      colorScheme = "green";
    }
    if ( new Date(lesson?.end_datetime) < new Date() ){
      status = "終了";
      colorScheme = "gray";
    }
    if ( lesson?.approval_flg != APPROVAL_FLG.APPROVED ){
      status = "教師承認待ち";
      colorScheme = "yellow";
    }
    if (lesson?.cancel_flg == CANCEL_FLG.CANCELED_COMPANY ){
      status = "キャンセル（企業）";
      colorScheme = "red";
    }
    if (lesson?.cancel_flg == CANCEL_FLG.CANCELED_TEACHER ){
      status = "キャンセル（講師）";
      colorScheme = "red";
    }
    if (lesson?.cancel_flg == CANCEL_FLG.CANCELED_MANAGER ){
      status = "キャンセル（運用）";
      colorScheme = "red";
    }
    if (lesson?.stop_flg == STOP_FLG.STOPPED ){
      status = "中止";
      colorScheme = "orange";
    }
    return (
      <>
        <Badge colorScheme={colorScheme} width="7.25rem" p={'5px'} textAlign="center">
          {status}
        </Badge>
      </>
    )
  }
