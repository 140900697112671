import { useColorModeValue } from '@chakra-ui/color-mode';
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  AlertStatus,
} from '@chakra-ui/react';
import { Pagination } from 'evergreen-ui';
import { ceil } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { requestServerApi, serverApi } from '../../api/api';
import '../../assets/styles/index.scss';
import { getFormattedDate, getFormattedTime } from '../../components/Format';
import { DELETE_FLG, STOP_FLG } from '../../configs';
import useErrorPage from '../../hooks/useErrorPage/useErrorPage';
import { lessonByLearnerId } from '../../interfaces/learnerInterface';
import { currentUserId } from '../shell';
import './index.scss';
import StudyHistoryModal from './Modal/StudyHistoryModal/StudyHistoryModal';
import NoteModal from './Modal/NoteModal/NoteModal';
import NoteEditModal from './Modal/NoteEditModal/NoteEditModal';
import FeedbackModal from './Modal/FeedbackModal/FeedbackModal';
import FeedbackEditModal from './Modal/FeedbackEditModal/FeedbackEditModal';
import useAppToast from '../../hooks/useAppToast/useAppToast';

export type code = {
  id: number;
  status: string;
  teacher: string;
  start_datetime: string;
  end_datetime: string;
};
export const getFormattedLessonTime = (lessonFrame: any) => {
  return lessonFrame && Object.keys(lessonFrame).length > 0
    ? `${getFormattedDate(lessonFrame.start_datetime)}`
    : '';
};

const learnerDetails = {
  create_datetime: new Date(),
  update_datetime: new Date(),
  email: '',
  payment_id: '',
  full_name: '',
  nick_name: '',
  learner_id: '',
  company_id: 1,
  company_name: '',
  stop_flg: STOP_FLG.AVAILABLE,
  delete_flg: DELETE_FLG.AVAILABLE,
  default_available_flg: '0',
  lesson_id: '',
  lesson_end_time: '',
  lesson_start_time: '',
};
export const FieldNames = {
  Story_010: 'ストーリー',
  Story_020: '回数',
  Story_030: '初回開始',
  Story_040: '初回終了',
  Story_050: '最終学習日',
  Talk_010: '話す',
  Talk_020: 'レッスン出席回数',
  Talk_030: 'レッスン欠席回数',
  Talk_040: 'レッスン出席率',
  Talk_050: 'レッスン合計時間',
  Self_010: '自習',
  Self_020: '直近3回の平均',
  Self_030: '回数',
  Self_040: 'ドリル',
  Common_001: '学習済み',
  Common_002: '学習中',
  Common_003: '未学習',
};
function Index() {
  const [Learner, SetLearner] = useState<any>();
  const lesson_id: string = useParams<any>().lessonId;
  const learner_id: string = useParams<any>().learnerId;
  const company_id: string = useParams<any>().companyId;
  const [lessonFutures, setLessonFutures] = useState<lessonByLearnerId[]>([]);
  const [totalLessonsCount, setTotalLessonsCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageLimit: number = 3;
  const [isLearnerStatusUpdated, setLearnerStatusUpdated] = useState<boolean>(false);
  const history = useHistory();
  const [confirming, setConfirming] = useState(false);
  const [refreshFlg, setRefreshFlg] = useState<any>([]);
  const [lessonFeedBack, setLessonFeedBack] = useState<any>([]);
  const [lesson, setLesson] = useState<any>([]);
  const [lessonLearnersList, setLessonLearnersList] = useState<any>([]);
  const teacher_id = useContext(currentUserId);
  const { apiErrorHandler } = useErrorPage();
  const [pastLesson, setPastLesson] = useState<any>([]);
  const { displayToast } = useAppToast();

  //get learner details
  const getLearnerDetails = () => {
    const response = requestServerApi<any>(() =>
      serverApi
        .organizationsControllerGetLearnerUserList(company_id, learner_id, -1, '', '', 1, 1)
        .catch((e) =>
          apiErrorHandler(e, {
            componentName: 'learner',
            functionName: serverApi.organizationsControllerGetLearnerUserList.name,
          }),
        ),
    );
    response.then((data) => {
      const apiResponse = data?.data;
      if (apiResponse.data[0]) {
        let learnerName = '';
        if (apiResponse.data[0]) {
          learnerName = apiResponse.data[0][0]?.nick_name;
        }
      }
      SetLearner(apiResponse?.data[0][0]);
    });
  };
  learnerDetails.company_id = Learner?.company_id;
  learnerDetails.learner_id = Learner?.learner_id;
  learnerDetails.full_name = Learner?.full_name;
  learnerDetails.nick_name = Learner?.nick_name;
  learnerDetails.delete_flg = Learner?.delete_flag;
  learnerDetails.stop_flg = Learner?.stop_flg;
  learnerDetails.create_datetime = Learner?.create_datetime;
  learnerDetails.email = Learner?.email;

  //get future lessons by learner ID
  const getAllLessonFutureByLearnerId = (company_id: string, learnerId: string) => {
    //list for storing api response
    let lessonList: any = [];

    const response = requestServerApi<any>(() =>
      serverApi
        .organizationsControllerGetFutureLessonListBgd(
          company_id,
          teacher_id,
          '',
          learnerId,
          '',
          '1',
          '',
          0,
          '',
          '',
          pageLimit,
          currentPage,
        )
        .catch((e) =>
          apiErrorHandler(e, {
            componentName: 'learner',
            functionName: serverApi.organizationsControllerGetFutureLessonListBgd.name,
          }),
        ),
    );
    response.then((data) => {
      const apiResponse = data?.data;
      lessonList = apiResponse?.data[0];
      if (apiResponse.data) {
        setTotalPages(
          ceil(apiResponse?.data[1] >= pageLimit ? apiResponse?.data[1] / pageLimit : 1),
        );
        setLessonFutures(lessonList);
        setTotalLessonsCount(apiResponse?.data[1]);

        //get lesson learners' list
        for (const lesson in lessonList) {
          let learners = new Array();
          let count = 1;
          let learners_array: any = [];

          //get lesson details
          const lessonResponse = requestServerApi<any>(() =>
            serverApi
              .organizationsControllerGetLesson(
                lessonList[lesson]?.company_id,
                teacher_id,
                lessonList[lesson]?.lesson_id,
                '',
                '',
                '',
                '',
              )
              .catch((e) =>
                apiErrorHandler(e, {
                  componentName: 'learner',
                  functionName: serverApi.organizationsControllerGetLesson.name,
                }),
              ),
          );
          lessonResponse.then((data) => {
            //store the api response
            const learnerApiResponse = data?.data;
            learners_array = learnerApiResponse?.data[0]?.t_lesson_learner;

            //store learner names
            if (learners_array?.length > 0) {
              for (const learner in learners_array) {
                //set lesson learners
                if (count <= pageLimit) {
                  learners.push(learners_array[learner]?.m_learner?.nick_name);
                  count += 1;
                }
                if (count > 3) {
                  learners[3] = ' 他';
                  break;
                }
              }
              learners = learners.toString() as any;
              //set lesson learners in a list
              setLessonLearnersList((learnerList: any) => [...learnerList, learners]);
            }
          });
        }
        //set feedback for lesson
        for (const i in lessonList) {
          if (lessonList[i]?.lesson_id == lesson_id) {
            setLessonFeedBack(lessonList[i]);
          }
        }
      }
    });
  };

  //get past lesson
  const getPastLesson = () => {
    const apiResponse = requestServerApi<any>(() =>
      serverApi
        .organizationsControllerGetPastLessonListBgd(
          company_id,
          teacher_id,
          '',
          '',
          '',
          learner_id,
          '',
          '',
          1,
          1,
        )
        .catch((e) =>
          apiErrorHandler(e, {
            componentName: 'learner',
            functionName: serverApi.organizationsControllerGetPastLessonListBgd.name,
          }),
        ),
    );
    apiResponse.then((data) => {
      setPastLesson(data.data?.data[0][0]);
    });
  };

  const onClickLessonDetail = (lesson_id: number, company_id: number) => {
    history.push(`/lesson/${company_id}/${lesson_id}`, {
      company_id: company_id,
    });
  };
  // フィードバック更新処理
  const onFeedbackUpdate = () => {
    const apiResponse = requestServerApi<any>(() =>
      serverApi
        .organizationsControllerUpdateLessonFeedback(
          pastLesson?.company_id,
          pastLesson?.lesson_id,
          pastLesson?.t_lesson_learner[0]?.learner_id,
          {
            feedback: pastLesson?.t_lesson_learner[0]?.feed_back,
          },
        )
        .catch((e) =>
          apiErrorHandler(e, {
            componentName: 'learner',
            functionName: serverApi.organizationsControllerUpdateLessonFeedback.name,
          }),
        ),
    );
    apiResponse.then((data) => {
      reactToUpdation(data?.data?.message, 'フィードバック更新');
      onCloseModal();
      setRefreshFlg(true);
    });
  };
  //get notes by lesson ID
  const getLesson = () => {
    const apiResponse = requestServerApi<any>(() =>
      serverApi
        .organizationsControllerGetLesson(company_id, teacher_id, lesson_id, '', '', '', '')
        .catch((e) =>
          apiErrorHandler(e, {
            componentName: 'learner',
            functionName: serverApi.organizationsControllerGetLesson.name,
          }),
        ),
    );
    apiResponse.then((data) => {
      if (data.data.data != undefined) {
        setLesson(data.data.data[0]);
      }
    });
  };
  // 申し送り一覧に遷移して学習者で絞り込み
  const onClickLessonNotes = () => {
    history.push(`/lesson_notes`, {
      company_id: company_id,
      learner_id: learner_id,
      learner_name: learnerDetails.nick_name,
    });
  };

  // フィードバックに遷移して学習者で絞り込み
  const onClickLessonFeedbacks = () => {
    history.push(`/feedback`, {
      company_id: company_id,
      learner_id: learner_id,
      learner_name: learnerDetails.nick_name,
    });
  };

  useEffect(() => {
    getLearnerDetails();
    getLesson();
    setRefreshFlg(false);
    setLessonLearnersList([]);
  }, [currentPage, teacher_id, refreshFlg == true]);

  useEffect(() => {
    if (teacher_id) {
      getAllLessonFutureByLearnerId(company_id, learner_id);
      getPastLesson();
    }
    setRefreshFlg(false);
  }, [currentPage, teacher_id, refreshFlg == true]);

  // Modal切り分け用
  const [selectedModal, setSelectedModal] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<any>('');

  // モーダルを開く
  const onOpenModal = (name: string, item: any) => {
    setConfirming(false);
    setSelectedModal(name);
    setSelectedItem(item);
  };

  // モーダルを閉じる
  const onCloseModal = () => {
    setSelectedModal('');
  };

  // 編集入力
  const handleNoteInputChange = (e: any) => {
    pastLesson['note'] = e.target.value;
    setPastLesson(pastLesson);
  };

  const handleFeedbackInputChange = (e: any) => {
    if (pastLesson?.t_lesson_learner) pastLesson.t_lesson_learner[0]['feed_back'] = e.target.value;
    setPastLesson(pastLesson);
  };

  // 申し送り更新処理
  const onNoteUpdate = () => {
    const apiResponse = requestServerApi<any>(() =>
      serverApi
        .organizationsControllerUpdateLessonNote(
          String(pastLesson.company_id),
          pastLesson.lesson_id,
          pastLesson.note,
        )
        .catch((e) =>
          apiErrorHandler(e, {
            componentName: 'learner',
            functionName: serverApi.organizationsControllerUpdateLessonNote.name,
          }),
        ),
    );
    apiResponse.then((data) => {
      reactToUpdation(data.data?.message, '申し送り事項更新');
      onCloseModal();
      setRefreshFlg(true);
    });
  };

  // ページ指定変更
  const handlePageChange = (targetPage: number) => {
    setCurrentPage(targetPage);
  };

  // 前のページへ
  const handlePreviousPage = () => {
    setCurrentPage((page: number) => page - 1);
    return;
  };

  // 次のページへ
  const handleNextPage = () => {
    setCurrentPage((page: number) => page + 1);
    return;
  };

  //　更新後の処理
  const reactToUpdation = (msg: string, toastTitle: string) => {
    var toastDescription: string =
      msg === 'NO_ERROR' ? `${toastTitle}に成功しました。` : `${toastTitle}に失敗しました。`;
    var toastStatus: AlertStatus = msg === 'NO_ERROR' ? 'success' : 'error';
    setTimeout(() => {
      setLearnerStatusUpdated(true);
      onCloseModal();
      displayToast(toastTitle, toastDescription, toastStatus);
      setLearnerStatusUpdated(false);
    }, 1000);
  };
  return (
    <>
      {selectedModal === 'studyHistoryModal' && <StudyHistoryModal onClose={onCloseModal} />}
      {'noteModal' === selectedModal && (
        <NoteModal onClose={onCloseModal} onOpenModal={onOpenModal} pastLesson={pastLesson} />
      )}
      {'noteEditModal' === selectedModal && (
        <NoteEditModal
          onClose={onCloseModal}
          handleNoteInputChange={handleNoteInputChange}
          pastLesson={pastLesson}
          onNoteUpdate={onNoteUpdate}
        />
      )}
      {'feedbackModal' === selectedModal && (
        <FeedbackModal onClose={onCloseModal} onOpenModal={onOpenModal} pastLesson={pastLesson} />
      )}
      {'feedbackEditModal' === selectedModal && (
        <FeedbackEditModal
          onClose={onCloseModal}
          onFeedbackUpdate={onFeedbackUpdate}
          handleFeedbackInputChange={handleFeedbackInputChange}
          pastLesson={pastLesson}
        />
      )}
      {/* 画面描画エリア */}
      <Flex justifyContent="space-between" alignItems="center">
        <Heading mt={5} size="lg" pb="1rem">
          学習者詳細：{learnerDetails?.nick_name}
        </Heading>
      </Flex>
      <Stat
        px={'10'}
        py={'10'}
        border={'1px solid #ddd'}
        borderColor={useColorModeValue('#ddd', '#ddd')}
        rounded={'md'}
        className="Learner-Stat"
      >
        <Flex justifyContent="space-between">
          <Box w="20em">
            <StatLabel fontWeight={'bold'} isTruncated whiteSpace={'pre-wrap'}>
              ID
            </StatLabel>
            <StatNumber fontSize={'lg'} fontWeight={'light'}>
              {learner_id}
            </StatNumber>
          </Box>
          <Box w="100px">
            <StatLabel fontWeight={'bold'} justifyContent="left">
              ステータス
            </StatLabel>
            <StatNumber fontWeight={'bold'}>
              {Learner?.delete_flg == '0' ? (
                <Badge
                  colorScheme="green"
                  style={{
                    lineHeight: '1.75rem',
                    width: '4rem',
                    textAlign: 'center',
                  }}
                  fontSize={'md'}
                >
                  有効
                </Badge>
              ) : (
                <Badge
                  colorScheme="red"
                  style={{
                    lineHeight: '1.75rem',
                    width: '4rem',
                    textAlign: 'center',
                  }}
                  fontSize={'md'}
                >
                  停止中
                </Badge>
              )}
            </StatNumber>
          </Box>
        </Flex>
        <Flex>
          <Box w="20em">
            <StatLabel fontWeight={'bold'} isTruncated whiteSpace={'pre-wrap'}>
              企業名
            </StatLabel>
            <StatNumber fontSize={'1xl'} fontWeight={'light'}>
              {lesson?.m_company?.company_name}
            </StatNumber>
          </Box>
        </Flex>
        <Flex>
          <HStack>
            <Box w="10em">
              <StatLabel fontWeight={'bold'} isTruncated whiteSpace={'pre-wrap'}>
                ニックネーム
              </StatLabel>
              <StatNumber fontSize={'1xl'} fontWeight={'light'}>
                {Learner?.nick_name}
              </StatNumber>
            </Box>
          </HStack>
        </Flex>

        <Flex>
          <Box w="20em">
            <StatLabel fontWeight={'bold'} isTruncated whiteSpace={'pre-wrap'}>
              学習履歴
            </StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'light'} justifyItems="center">
              <Button
                mr={'5'}
                style={{ width: '8rem', height: '2rem' }}
                colorScheme={'teal'}
                onClick={() => onOpenModal('studyHistoryModal', '')}
              >
                学習履歴
              </Button>
            </StatNumber>
          </Box>
        </Flex>
        <Flex>
          <Box>
            <StatLabel fontWeight={'bold'} isTruncated whiteSpace={'pre-wrap'}>
              直近に実施したレッスン
            </StatLabel>
            <Flex w="200px">
              <Box w="20em">
                <StatNumber fontSize={'2xl'} fontWeight={'light'}>
                  <Button
                    mr={5}
                    style={{ width: '8rem' }}
                    colorScheme="teal"
                    size="sm"
                    disabled={!pastLesson}
                    onClick={() => {
                      onOpenModal('noteModal', null);
                    }}
                  >
                    申し送り
                  </Button>
                </StatNumber>
              </Box>
              <Box>
                <StatNumber fontSize={'2xl'} fontWeight={'light'}>
                  <Button
                    mr={5}
                    style={{ width: '8rem' }}
                    colorScheme="teal"
                    size="sm"
                    onClick={() => {
                      onOpenModal('feedbackModal', null);
                    }}
                    disabled={!pastLesson}
                  >
                    フィードバック
                  </Button>
                </StatNumber>
              </Box>
            </Flex>
          </Box>

          <Box>
            <StatLabel fontWeight={'bold'} isTruncated whiteSpace={'pre-wrap'}>
              一覧検索
            </StatLabel>
            <Flex w="200px">
              <Box w="20em">
                <StatNumber fontSize={'2xl'} fontWeight={'light'}>
                  <Button
                    mr={5}
                    style={{ width: '8rem' }}
                    colorScheme="teal"
                    size="sm"
                    onClick={() => onClickLessonNotes()}
                  >
                    申し送り一覧
                  </Button>
                </StatNumber>
              </Box>
              <Box>
                <StatNumber fontSize={'2xl'} fontWeight={'light'}>
                  <Button
                    mr={5}
                    style={{ width: '8rem' }}
                    colorScheme="teal"
                    size="sm"
                    onClick={() => onClickLessonFeedbacks()}
                  >
                    フィードバック一覧
                  </Button>
                </StatNumber>
              </Box>
              <Box />
            </Flex>
          </Box>
        </Flex>
        <Flex></Flex>
      </Stat>
      <Heading mt={5} size="lg" pt="2rem" pb="1rem">
        レッスン一覧（実施予定）
      </Heading>
      <Box className="Learner-Table">
        <Table variant="simple" height="200">
          <Thead className="Learner-TableHead">
            <Tr>
              <Th />
              <Th width="20%">日付</Th>
              <Th width="20%">時間</Th>
              <Th width="20%">教師</Th>
              <Th width="20%">参加学習者</Th>
            </Tr>
          </Thead>
          <Tbody className="Learner-TableBody">
            {lessonFutures?.map((lesson: any, index: number) => (
              <Tr
                key={index}
                onClick={() => onClickLessonDetail(lesson?.lesson_id, lesson?.company_id)}
              >
                <Td>
                  <Button
                    colorScheme="linkedin"
                    size="sm"
                    onClick={() => onClickLessonDetail(lesson?.lesson_id, lesson?.company_id)}
                  >
                    詳細
                  </Button>
                </Td>
                <Td>{getFormattedDate(lesson?.start_datetime)}</Td>
                <Td>
                  {getFormattedTime(lesson?.start_datetime)} ~{' '}
                  {getFormattedTime(lesson?.end_datetime)}
                </Td>
                <Td>{lesson?.t_lesson_teacher[0]?.m_teacher?.full_name}</Td>
                <Td>{lessonLearnersList[index]}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Box className="Learner-PaginationArea">
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onNextPage={handleNextPage}
            onPreviousPage={handlePreviousPage}
          ></Pagination>
        </Box>{' '}
      </Box>
    </>
  );
}

export default Index;
