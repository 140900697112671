import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import ModalHeaderComponent from '../../../../components/ModalHeader';
import { useState } from 'react';

type Props = {
  onClose: () => void;
  handleInputChange: (e: any) => void;
  selectedItem: any;
  updateFeedBack: () => void;
};

export default function FeedbackEditModal({
  onClose,
  handleInputChange,
  updateFeedBack,
  selectedItem,
}: Props) {
  const [confirming, setConfirming] = useState(false);
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent minW="55rem">
          <ModalHeaderComponent
            title={confirming == false ? 'フィードバック編集' : 'フィードバック編集'}
          />
          <ModalBody>
            <Flex justifyContent="right"></Flex>
            <Textarea
              placeholder="レッスンの内容や学習者のについて記入してください。"
              onChange={handleInputChange}
              isDisabled={confirming == true}
              defaultValue={selectedItem != '' ? selectedItem?.feed_back : ''}
              rows={3}
            ></Textarea>
          </ModalBody>
          {confirming == false ? (
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={() => setConfirming(true)}>
                更新
              </Button>
              <Button colorScheme="gray" mr={3} onClick={closeModal}>
                キャンセル
              </Button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={() => updateFeedBack()}>
                更新
              </Button>
              <Button colorScheme="gray" mr={3} onClick={() => setConfirming(false)}>
                修正
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
