import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FieldNames } from '../../..';
import '../../../../../assets/styles/index.scss';
import MedalIcon from '../../../../../components/icon/Medal/Medal';
import { STORY_EPISODE_ID_SECTION_INDEX, TOTAL_COUNT } from '../../../../../configs';
import '../../../index.scss';
import EpisodeScenes from './EpisodeScenes';

type Props = {
  chapter: any;
  storyRecords: any[];
};
const Episode = ({ chapter, storyRecords }: Props) => {
  const episodeName = chapter?.name;
  const [pending, setPending] = useState(0);
  const [complete, setComplete] = useState(0);
  const [episodeCorrectCount, setEpisodeCorrectCount] = useState(0);
  const [episodeTotalCount, setEpisodeTotalCount] = useState(0);

  const calcProgressStatus = () => {
    if (!storyRecords || !chapter?.sections || !Array.isArray(chapter?.sections)) {
      return;
    }

    let _complete = 0;
    let _pending = 0;
    storyRecords
      .filter(
        (story) =>
          (chapter?.sections as any[])
            .map((scene) => {
              // chapter(scene)のIDがずれているため合わせる必要がある。
              // 学習履歴は0始まり
              const tempId = `${scene?.Id}`.replace('_SECTION_ID', '').replace(/\d+$/, '');
              const sectionNo = `${scene?.Id}`.replace('_SECTION_ID', '').match(/\d+$/g);
              if (sectionNo && sectionNo.length > 0) {
                return `${tempId}${parseInt(sectionNo[0]) - 1}`;
              }
            })
            .includes(story?.chapter_id) ||
          story?.chapter_id === `${chapter.Id}_${STORY_EPISODE_ID_SECTION_INDEX['Ep.x summary']}`,
      )
      .forEach((story) => {
        if (story?.study_status === 'Complete') {
          console.log('_complete', _complete);
          _complete++;
        } else if (story?.study_status === 'Studying') {
          _pending++;
        }
      });
    setComplete(_complete);
    setPending(_pending);
  };

  const calcQuestionCount = () => {
    if (!storyRecords || !chapter?.sections || !Array.isArray(chapter?.sections)) {
      return;
    }
    let _episodeCorrectCount = 0;
    // NOTE 問題数が固定されているため、定数でOK
    let _episodeTotalCount = TOTAL_COUNT.SCENE * 3 + TOTAL_COUNT.EPISODE_SUMMARY;
    storyRecords.forEach((story) => {
      (chapter?.sections as any[])
        .filter(
          (scene) =>
            Object.entries(STORY_EPISODE_ID_SECTION_INDEX)
              .map(([_, v]) => `${scene?.chapter}_${v}`)
              .includes(story?.chapter_id) ||
            story?.chapter_id === `${chapter.Id}_${STORY_EPISODE_ID_SECTION_INDEX['Ep.x summary']}`,
        )
        .forEach((_) => {
          _episodeCorrectCount += story.correct;
        });
    });

    setEpisodeCorrectCount(_episodeCorrectCount);
    setEpisodeTotalCount(_episodeTotalCount);
  };

  useEffect(() => {
    calcProgressStatus();
    calcQuestionCount();
  }, []);

  return (
    <>
      <Accordion
        m="5px"
        backgroundColor="white"
        allowMultiple
        border="1px solid Black"
        key={chapter?.Id}
      >
        <AccordionItem>
          <h2>
            <AccordionButton justifyContent="space-between">
              <Flex justifyContent="space-between" w="100%">
                <Box>
                  <Flex alignItems="center">
                    <Box w="max-content">{episodeName}</Box>
                  </Flex>
                </Box>
                <Box>
                  <Flex alignItems="center">
                    <Box
                      backgroundColor="white"
                      textAlign="center"
                      fontSize="xs"
                      width="max-content"
                    >
                      {pending > 0 || complete > 0 ? (
                        complete === chapter.sections.length + 1 ? (
                          <Badge colorScheme="cyan" width="6rem" p={'5px'}>
                            {/* 学習済み */}
                            {FieldNames.Common_001}
                          </Badge>
                        ) : (
                          <Badge colorScheme="yellow" width="6rem" p={'5px'}>
                            {/* 学習中 */}
                            {FieldNames.Common_002}
                          </Badge>
                        )
                      ) : (
                        <Badge colorScheme="green" width="6rem" p={'5px'}>
                          {/* 未学習 */}
                          {FieldNames.Common_003}
                        </Badge>
                      )}
                    </Box>
                  </Flex>
                </Box>
                <Box>
                  <MedalIcon correctCount={episodeCorrectCount} totalCount={episodeTotalCount} />
                </Box>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} backgroundColor="white">
            <EpisodeScenes episode={chapter} storyRecords={storyRecords} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
export default Episode;
