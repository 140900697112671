import {
  Stack,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import { CheckIcon, ViewIcon } from '@chakra-ui/icons';
import ModalHeaderComponent from '../../../components/ModalHeader';
import { Form, Formik } from 'formik';
import { InputControl } from 'formik-chakra-ui';
import { useState } from 'react';

type Props = {
  onClose: () => void;
  handlelessonFeeOptionCreation: (values: any) => void;
};

export default function CreateLessonFeeOptionModal({
  onClose,
  handlelessonFeeOptionCreation,
}: Props) {
  const [confirming, setConfirming] = useState(false);
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeaderComponent title={confirming ? 'レッスン料 確認' : 'オプション請求登録'} />
          <Formik
            initialValues={{
              teacher_id: '',
              year_month: '',
              item_name: '',
              unit_price: 0,
              item_count: 0,
              tax_rate: 10,
              total_amount: 0,
            }}
            onSubmit={(values, { setSubmitting }) => {
              handlelessonFeeOptionCreation(values);
              setTimeout(() => {
                setSubmitting(false);
                setConfirming(false);
              }, 1000);
            }}
          >
            {({ values, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <ModalBody pb={2} justifyContent="center" alignItems="center" display="flow">
                    <Stack spacing={2}>
                      <InputControl name="item_name" label="項目名" isDisabled={confirming} />{' '}
                      <InputControl name="unit_price" label="単価" isDisabled={confirming} />
                      <InputControl name="item_count" label="数量" isDisabled={confirming} />
                      <InputControl name="tax_rate" label="税率" isDisabled={confirming} />
                    </Stack>
                  </ModalBody>
                  {confirming == true ? (
                    <ModalFooter>
                      <Button
                        type="submit"
                        colorScheme="gray"
                        leftIcon={<CheckIcon />}
                        mr={3}
                        disabled={
                          values.item_name == '' ||
                          values.unit_price == 0 ||
                          values.item_count == 0 ||
                          isValid == false
                        }
                      >
                        登録
                      </Button>
                      <Button colorScheme="gray" onClick={() => setConfirming(false)}>
                        修正
                      </Button>
                    </ModalFooter>
                  ) : null}
                </Form>
                {confirming == false ? (
                  <ModalFooter>
                    <Button
                      colorScheme="gray"
                      mr={3}
                      leftIcon={<ViewIcon />}
                      type="button"
                      onClick={() => setConfirming(true)}
                    >
                      登録確認
                    </Button>
                    <Button colorScheme="gray" onClick={closeModal}>
                      キャンセル
                    </Button>
                  </ModalFooter>
                ) : null}
              </>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
}
