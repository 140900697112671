import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Box,
} from '@chakra-ui/react';
import ModalHeaderComponent from '../../../components/ModalHeader';

type Props = {
  onClose: () => void;
  selectedItem: any;
  deleteFeeOption: (branch_number: number) => void;
};

export default function DeleteLessonOptionModal({ onClose, deleteFeeOption, selectedItem }: Props) {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeaderComponent title={'レコードを削除してもよろしいですか?'} />
          <ModalBody textColor="red">
            <Box>※この記録は完全に削除されます。</Box>
            <Box>間違って削除しないようご注意ください</Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => deleteFeeOption(selectedItem?.branch_number)}
            >
              削除
            </Button>
            <Button onClick={closeModal}>閉じる</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
