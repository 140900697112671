import { Center } from '@chakra-ui/react';
import React from 'react';
import DrillRecords from './Drill';

type Props = {
  selfStudyRecords: any[];
};
const SelfStudyRecords = ({ selfStudyRecords }: Props) => {
  return (
    <>
      <DrillRecords drillRecords={selfStudyRecords} />
      {selfStudyRecords.length === 0 && (
        <Center backgroundColor="yellow"> ドリルデータがありません </Center>
      )}
    </>
  );
};
export default SelfStudyRecords;
