import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import ModalHeaderComponent from '../../../../components/ModalHeader';
import { getFormattedDate, getFormattedTime } from '../../../../components/Format';

interface Note {
  company_id: string;
  lesson_id: string;
  start_datetime: string;
  end_datetime: string;
  teacher_name: string;
  learner_name: string;
  m_company: company;
  note: string;
}

interface company {
  company_name: string;
}

type Props = {
  onClose: () => void;
  onOpenModal: (name: string, item: any) => void;
  selectedItem: Note;
};

export default function NoteModal({ onClose, onOpenModal, selectedItem }: Props) {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent minW="55rem">
          <ModalHeaderComponent title={'申し送り詳細'} />
          <ModalBody>
            <Flex justifyContent="right">
              <Box> 先生 → {selectedItem?.m_company?.company_name}</Box>
            </Flex>
            <Flex>レッスン日時</Flex>
            <Flex border="1px solid #ddd" p={2} maxW="100%" overflowY="auto">
              <Box w="100%">
                {getFormattedDate(selectedItem.start_datetime)}
                {getFormattedTime(selectedItem.start_datetime)}～
                {getFormattedTime(selectedItem.end_datetime)}
              </Box>
            </Flex>
            <Flex>申し送り内容</Flex>
            <p
              className={selectedItem.note ? 'LessonNotes-details' : 'LessonNotes-details-readOnly'}
              contentEditable={false}
            >
              {selectedItem.note || '申し送りが入力されていません'}
            </p>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="right">
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  onOpenModal('noteEditModal', {
                    company_id: selectedItem.company_id,
                    lesson_id: selectedItem.lesson_id,
                    lesson_day: getFormattedDate(selectedItem.start_datetime),
                    start_time: getFormattedTime(selectedItem.start_datetime),
                    end_time: getFormattedTime(selectedItem.end_datetime),
                    teacher_name: selectedItem.teacher_name,
                    learner_name: selectedItem.learner_name,
                    company_name: selectedItem?.m_company?.company_name,
                    note: selectedItem.note,
                  });
                }}
              >
                編集
              </Button>
              <Button colorScheme="gray" mr={3} onClick={closeModal}>
                閉じる
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
