import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import ModalHeaderComponent from '../../../../components/ModalHeader';
import { useState } from 'react';

interface Note {
  teacher_name: string;
  company_name: string;
  lesson_day: string;
  start_time: string;
  end_time: string;
  note: string;
  company_id: string;
  lesson_id: string;
  learner_name: string;
}

type Props = {
  onClose: () => void;
  selectedItem: Note;
  noteUpdating: boolean;
  handleInputChange: (e: any) => void;
  onNoteUpdate: () => void;
};

export default function NoteEditModal({
  onClose,
  selectedItem,
  noteUpdating,
  handleInputChange,
  onNoteUpdate,
}: Props) {
  const [confirming, setConfirming] = useState(false);
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent minW="55rem">
          <ModalHeaderComponent title={confirming == false ? '申し送り編集' : '申し送り更新確認'} />
          <ModalBody>
            <Flex justifyContent="right">
              <Box>先生 → {selectedItem.company_name}</Box>
            </Flex>
            <Textarea
              placeholder="申し送りを入力してください。"
              onChange={handleInputChange}
              isDisabled={confirming == true}
              defaultValue={selectedItem.note}
            ></Textarea>
          </ModalBody>
          {confirming == false ? (
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={() => setConfirming(true)}>
                更新
              </Button>
              <Button colorScheme="gray" mr={3} onClick={closeModal}>
                キャンセル
              </Button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                disabled={noteUpdating}
                onClick={() => onNoteUpdate()}
              >
                更新
              </Button>
              <Button colorScheme="gray" mr={3} onClick={() => setConfirming(false)}>
                修正
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
