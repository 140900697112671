import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { requestServerApi, serverApi } from '../../../../../api/api';
import { S3_PATH } from '../../../../../configs';
import SeasonSummary from './SeasonSummary';
import Episode from './Episode';

type Props = {
  storyRecords: any[];
};
const StoryStudyRecords = ({ storyRecords }: Props) => {
  const [stories, setStories] = useState<any[]>([]);

  const getStoryList = useCallback(() => {
    fetch(S3_PATH + 'list_season_chapter_section_staging.json', { method: 'GET' })
      .then((res) => res.json())
      .then(async (data) => {
        setStories(data);
      });
  }, [S3_PATH]);

  useEffect(() => {
    getStoryList();
  }, []);
  return (
    <>
      {stories
        .filter((story) => story?.chapters && Array.isArray(story?.chapters))
        .map((story, i) => (
          <div key={i}>
            {story.chapters.map((chapter: any, i: number) => (
              <Episode chapter={chapter} storyRecords={storyRecords} key={chapter.Id} />
            ))}
            <SeasonSummary
              storyRecords={storyRecords}
              name={story?.name}
              id={story.Id}
              key={story.Id}
            />
          </div>
        ))}
    </>
  );
};
export default StoryStudyRecords;
