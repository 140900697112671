import { useContext, useEffect, useState } from 'react';
import { requestServerApi, serverApi } from '../../api/api';
import { useAppSelector } from '../../hooks';
import { currentUserId } from '../../pages/shell';
import { useLocation } from 'react-router-dom';
import useErrorPage from '../useErrorPage/useErrorPage';

export default function useLessonByIdAndCompanyId(
  lessonId: string,
  companyId: string,
  isRefresh: boolean = false,
) {
  const userState = useAppSelector((state) => state.user);
  const teacherId = useContext(currentUserId);
  const username = userState.user?.['cognito:username'] as string;
  const [lesson, setLesson] = useState<any>();
  const { apiErrorHandler } = useErrorPage();

  // FIXME コールバックのほうがいいかも
  useEffect(() => {
    const getLesson = async () => {
      const response = await requestServerApi<any>(() =>
        // FIXME 開始直前のレッスンだけを取得するエンドポイントが存在しないのか？
        serverApi.organizationsControllerGetLesson(
          companyId,
          username ?? teacherId,
          lessonId,
          '',
          '',
          '',
          '',
        ),
      ).catch((error) => {
        setLesson(null);
        apiErrorHandler(error, {
          componentName: 'useLesson',
          functionName: serverApi.organizationsControllerGetLesson.name,
        });
      });
      try {
        // TODO データ型と値チェック
        if (response.data.data && Array.isArray(response.data.data)) {
          setLesson(response.data.data[0]);
        }
      } catch (error) {
        setLesson(null);
        apiErrorHandler(error, {
          componentName: 'useLessonById',
          functionName: serverApi.organizationsControllerGetLesson.name,
        });
      }
    };

    getLesson();
  }, [companyId, username, lessonId, isRefresh]);

  return { lesson };
}
